import React from 'react';
import { graphql } from 'gatsby';

import WrapPbSection from 'components/common/WrapPbSection';
import WrapSection from 'components/common/WrapSection';
import ImageText from 'components/common/ImageText';

export default function PageBuilderImageText({ data, backgroundImage }) {
  const {
    teaserTagline,
    teaserTitle,
    teaserTitleSize,
    teaserText,
    teaserLinkTitle,
    teaserLinkField,
    teaserStripe,
    teaserImagePrimary,
    teaserImageSecondary,
    teaserImageAlignment,
    teaserAlignment,
  } = data;

  return (
    <WrapPbSection>
      <WrapSection
        backgroundImage={backgroundImage}
        backgroundFlip={teaserAlignment === 'left'}
        posed
      >
        <ImageText
          teaserStyledAlign={teaserAlignment}
          tagline={teaserTagline}
          headline={teaserTitle}
          isHeadlineLarge={teaserTitleSize}
          text={teaserText}
          hasStripe={teaserStripe}
          align={teaserAlignment}
          buttonLink={
            teaserLinkField?.entry
              ? teaserLinkField?.entry?.fullUri
              : teaserLinkField?.url
          }
          buttonText={teaserLinkTitle}
          buttonTarget={teaserLinkField?.target}
          imagePrimary={teaserImagePrimary?.[0]}
          imageSecondary={teaserImageSecondary?.[0]}
          imageAlignment={teaserImageAlignment}
        />
      </WrapSection>
    </WrapPbSection>
  );
}

export const query = graphql`
  fragment PageBuilderImageTextQuery on Craft_PageBuilderNeoImageText {
    id
    teaserTagline
    teaserTitle {
      content
    }
    teaserTitleSize
    teaserText
    teaserLinkTitle
    teaserLinkField {
      entry {
        id
        fullUri
      }
      url
      target
    }
    teaserStripe
    teaserImagePrimary {
      ...CraftImageFluid_ImageText
    }
    teaserImageSecondary {
      ...CraftImageFluid_ImageText
    }
    teaserImageAlignment
    teaserAlignment
  }
`;
