import React from 'react';
import { graphql } from 'gatsby';

import SectionProducts from 'components/common/SectionProducts';

import { PageBuilderSliderFadeStyled } from './PageBuilderSliderFadeStyled';

export default function PageBuilderSliderFade({ data }) {
  const { children } = data;
  return (
    <PageBuilderSliderFadeStyled>
      <SectionProducts entries={children} />
    </PageBuilderSliderFadeStyled>
  );
}

export const query = graphql`
  fragment PageBuilderSliderFadeQuery on Craft_PageBuilderNeoSliderFade {
    children {
      __typename
      ... on Craft_PageBuilderNeoSlideProductBeer {
        id
        teaserTitleSize
        teaserStripe
        teaserAlignment
        teaserLinkTitle
        teaserLinkField {
          url
        }
        teaserText
        teaserTitle {
          content
        }
        teaserTagline
        beerCover {
          ...CraftImageFluid
        }
        beers {
          id
        }
      }
    }
  }
`;
