import React from 'react';
import { graphql } from 'gatsby';
import Stage from 'components/common/Stage';

export default function PageBuilderStage({ data }) {
  const { stageImage, stageTitle, stageTagline, stageText } = data;

  return (
    <Stage
      image={stageImage}
      headline={stageTitle?.content}
      tagline={stageTagline}
      text={stageText?.content}
    />
  );
}

export const query = graphql`
  fragment PageBuilderStageQuery on Craft_PageBuilderNeoStage {
    id
    stageTagline
    stageTitle {
      content
    }
    stageText {
      content
    }
    stageImage {
      url
      ...CraftImageFluid_Stage
      focalPoint {
        x
        y
      }
    }
  }
`;
