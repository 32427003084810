import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'components/common/Grid';
import NullWrap from 'components/common/NullWrap';

import {
  SectionProductsFlickityStyled,
  SectionProductsStyled,
  SectionProductsGridItemStyled,
  SectionProductsTextTeaserStyled,
} from './SectionProductsStyled';

export default function SectionProducts({ entries }) {
  const Slider = entries.length > 1 ? SectionProductsFlickityStyled : NullWrap;

  return entries ? (
    <Slider
      getOptions={() => ({
        adaptiveHeight: true,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: true,
        fade: true,
        draggable: true,
      })}
    >
      {entries?.map((entry, index) => (
        <SectionProductsStyled
          key={entry?.id}
          backgroundImage={entry?.beerCover?.[0]}
          backgroundImageProducts
          flex
        >
          <Grid right>
            <SectionProductsGridItemStyled mobile={4 / 5} tabletFluid={1 / 2}>
              <SectionProductsTextTeaserStyled
                tagline={`${index + 1}/${entries.length}`}
                headline={entry?.teaserTitle}
                isHeadlineLarge={entry?.teaserTitleSize}
                text={entry?.teaserText}
                align={entry?.teaserAlignment}
                hasStripe={entry?.teaserStripe}
                buttonPrimaryLink={entry?.teaserLinkField?.url}
                buttonPrimaryText={entry?.teaserLinkTitle}
              />
            </SectionProductsGridItemStyled>
          </Grid>
        </SectionProductsStyled>
      ))}
    </Slider>
  ) : null;
}

SectionProducts.propTypes = {
  entries: PropTypes.array,
};

SectionProducts.defaultProps = {
  entries: null,
};
