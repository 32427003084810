import React from 'react';
import PropTypes from 'prop-types';

import { parseAsContent } from 'utils/parser';

import Grid from 'components/common/Grid';
import GridItem from 'components/common/GridItem';
import HeadlineGold from 'components/common/HeadlineGold';
import Text from 'components/common/Text';
import Link from 'components/common/Link';
import Image from 'components/common/ImageGatsbyCraft';
import WrapSection from 'components/common/WrapSection';

import {
  SectionSocialsHeadStyled,
  SectionSocialsColumnStyled,
  SectionSocialsLinkStyled,
  SectionSocialsFacebookStyled,
  SectionSocialsFacebookIconStyled,
  SectionSocialsFacebookContentStyled,
  SectionSocialsFacebookSublineStyled,
  // SectionSocialsTwitterStyled,
  // SectionSocialsTwitterIconStyled,
  // SectionSocialsTwitterContentStyled,
  // SectionSocialsTwitterSublineStyled,
  SectionSocialsInstagramStyled,
  SectionSocialsInstagramIconStyled,
  SectionSocialsInstagramContentStyled,
  SectionSocialsInstagramSublineStyled,
  SectionSocialsInstagramTeaserTextStyled,
  SectionSocialsInstagramTeaserIconStyled,
  SectionSocialsYoutubeStyled,
  SectionSocialsYoutubeIconStyled,
  SectionSocialsYoutubeContentStyled,
  SectionSocialsYoutubeSublineStyled,
  SectionSocialsYoutubeTeaserStyled,
  SectionSocialsYoutubeTeaserIconStyled,
} from './SectionSocialsStyled';

export default function SectionSocials({
  headline,
  subline,
  backgroundImage,
  instagramTeaserBackground,
  instagramTeaserText,
  instagramTeaserLink,
  instagramText,
  instagramLink,
  facebookText,
  facebookLink,
  youtubeText,
  youtubeLink,
  youtubeTeaserBackground,
  youtubeTeaserLink,
  // twitterText,
  // twitterLink,
}) {
  return (
    <WrapSection backgroundImage={backgroundImage}>
      <Grid>
        <GridItem>
          <SectionSocialsHeadStyled>
            <HeadlineGold element="h2">
              {parseAsContent(headline?.content || headline, {
                onlyBasicTags: true,
              })}
            </HeadlineGold>
            <Text>
              {parseAsContent(subline?.content || subline, {
                onlyBasicTags: true,
              })}
            </Text>
          </SectionSocialsHeadStyled>
        </GridItem>
        <GridItem laptop={1 / 3}>
          <Link to={instagramTeaserLink} target="_blank">
            <SectionSocialsColumnStyled aspectRatio={1}>
              <Image
                args={{ maxWidth: 1920 }}
                fluid={instagramTeaserBackground}
              />
              <SectionSocialsInstagramTeaserIconStyled type="socialInstagram" />
              <SectionSocialsInstagramTeaserTextStyled>
                {instagramTeaserText}
              </SectionSocialsInstagramTeaserTextStyled>
            </SectionSocialsColumnStyled>
          </Link>
        </GridItem>
        <GridItem laptop={1 / 3}>
          <SectionSocialsColumnStyled aspectRatio={1} multiple>
            <SectionSocialsLinkStyled to={instagramLink} target="_blank">
              <SectionSocialsInstagramStyled>
                <SectionSocialsInstagramContentStyled>
                  <SectionSocialsInstagramSublineStyled>
                    {instagramText}
                  </SectionSocialsInstagramSublineStyled>
                </SectionSocialsInstagramContentStyled>
                <SectionSocialsInstagramIconStyled
                  type="socialInstagram"
                  width={36}
                  height={36}
                />
              </SectionSocialsInstagramStyled>
            </SectionSocialsLinkStyled>
            <SectionSocialsLinkStyled to={facebookLink} target="_blank">
              <SectionSocialsFacebookStyled>
                <SectionSocialsFacebookIconStyled
                  type="socialFacebook"
                  width={36}
                  height={36}
                />
                <SectionSocialsFacebookContentStyled>
                  <SectionSocialsFacebookSublineStyled>
                    {facebookText}
                  </SectionSocialsFacebookSublineStyled>
                </SectionSocialsFacebookContentStyled>
              </SectionSocialsFacebookStyled>
            </SectionSocialsLinkStyled>
            {
              // Don´t show twitter on NL website
              /*  <SectionSocialsLinkStyled to={twitterLink} target="_blank">
              <SectionSocialsTwitterStyled>
                <SectionSocialsTwitterIconStyled
                  type="socialTwitter"
                  width={36}
                  height={36}
                />
                <SectionSocialsTwitterContentStyled>
                  <SectionSocialsTwitterSublineStyled>
                    {twitterText}
                  </SectionSocialsTwitterSublineStyled>
                </SectionSocialsTwitterContentStyled>
              </SectionSocialsTwitterStyled>
            </SectionSocialsLinkStyled> */
            }
          </SectionSocialsColumnStyled>
        </GridItem>
        <GridItem laptop={1 / 3}>
          <SectionSocialsColumnStyled aspectRatio={1} multiple>
            <SectionSocialsYoutubeTeaserStyled
              to={youtubeTeaserLink}
              target="_blank"
            >
              <Image
                fluid={youtubeTeaserBackground}
                args={{ maxWidth: 1920 }}
                style={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  top: 0,
                  left: 0,
                }}
              />
              <SectionSocialsYoutubeTeaserIconStyled
                type="socialYoutube"
                width={56}
                height={56}
              />
            </SectionSocialsYoutubeTeaserStyled>
            <SectionSocialsLinkStyled to={youtubeLink} target="_blank">
              <SectionSocialsYoutubeStyled>
                <SectionSocialsYoutubeContentStyled>
                  <SectionSocialsYoutubeSublineStyled>
                    {youtubeText}
                  </SectionSocialsYoutubeSublineStyled>
                </SectionSocialsYoutubeContentStyled>
                <SectionSocialsYoutubeIconStyled
                  type="socialYoutube"
                  width={46}
                  height={46}
                />
              </SectionSocialsYoutubeStyled>
            </SectionSocialsLinkStyled>
          </SectionSocialsColumnStyled>
        </GridItem>
      </Grid>
    </WrapSection>
  );
}

SectionSocials.propTypes = {
  instagramTeaserLink: PropTypes.string,
  instagramLink: PropTypes.string,
  facebookLink: PropTypes.string,
  facebookText: PropTypes.string,
  // twitterLink: PropTypes.string,
  // twitterText: PropTypes.string,
  youtubeLink: PropTypes.string,
  youtubeText: PropTypes.string,
  youtubeTeaserLink: PropTypes.string,
};

SectionSocials.defaultProps = {
  instagramTeaserLink: 'https://www.instagram.com/p/Bsdfr16FrLX/',
  instagramLink: 'https://www.instagram.com/warsteiner/',
  facebookLink: 'https://www.facebook.com/Warsteiner/',
  facebookText: 'Facebook',
  // twitterLink: 'https://twitter.com/warsteiner?lang=de',
  // twitterText: 'Twitter',
  youtubeLink: 'https://www.youtube.com/user/WarsteinerVideos',
  youtubeText: 'YouTube',
  youtubeTeaserLink: 'https://www.youtube.com/watch?v=cUaK6yGOxSA',
};
