import styled from 'utils/emotion';
import { toPx } from 'utils/styles';
import media from 'utils/mediaqueries';

import Icon from 'components/common/Icon';
import Embed from 'components/common/Embed';
import Link from 'components/common/Link';
import Text from 'components/common/Text';

export const SectionSocialsColumnStyled = styled(Embed)({}, (props) => ({
  margin: `0 0 ${toPx(props.theme.spaceThird)} 0`,
  svg: {
    '*': {
      fill: 'white',
    },
  },
}));

export const SectionSocialsHeadStyled = styled('div')({}, (props) => ({
  padding: `${toPx(props.theme.spaceDouble)} 0`,
  textAlign: 'center',
}));

export const SectionSocialsLinkStyled = styled(Link)({
  '&:hover': {
    svg: {
      transition: 'opacity .25s ease-in-out',
      opacity: 0.8,
    },
  },
});

export const SectionSocialsContentStyled = styled('div')({
  position: 'relative',
  zIndex: 2,
});

// Youtube teaser (with image)

export const SectionSocialsYoutubeTeaserStyled = styled(Link)(
  {
    display: 'block',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
  },
  (props) => ({
    color: props.theme.colorTertiary,
    height: `calc(70% - ${toPx(props.theme.spaceThird)})`,
    [media('laptop')]: {
      height: `calc(70% - ${toPx(props.theme.spaceDouble)})`,
    },
    ':after': {
      content: '""',
      zIndex: 4,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: toPx(props.theme.spaceQuatered),
      height: toPx(props.theme.spaceQuatered),
      background: props.theme.colorPrimary,
    },
  }),
);

// Instagram Teaser (with image)

export const SectionSocialsInstagramTeaserTextStyled = styled(Text)(
  {
    position: 'absolute',
    zIndex: 5,
    margin: 0,
  },
  (props) => ({
    top: props.theme.space,
    left: props.theme.space,
    fontSize: toPx(props.theme.spaceThirds),
    lineHeight: toPx(props.theme.space),
    color: props.theme.colorSecondary,
  }),
);

export const SectionSocialsInstagramTeaserIconStyled = styled(Icon)(
  {
    position: 'absolute',
    zIndex: 5,
  },
  (props) => ({
    top: props.theme.space,
    right: props.theme.space,
    color: props.theme.colorTertiary,
  }),
);

// Youtube

export const SectionSocialsYoutubeStyled = styled('div')(
  {
    position: 'absolute',
    width: '100%',
    bottom: '0',
    left: '0',
    height: '30%',
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  (props) => ({
    border: `1px solid ${props.theme.colorTertiary}`,
    padding: props.theme.space,
  }),
);

export const SectionSocialsYoutubeIconStyled = styled(Icon)(
  {
    position: 'relative',
    alignSelf: 'flex-end',
    zIndex: 5,
  },
  (props) => ({
    color: props.theme.colorQuaternary,
  }),
);

export const SectionSocialsYoutubeTeaserIconStyled = styled(Icon)({
  position: 'absolute',
  zIndex: 5,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export const SectionSocialsYoutubeContentStyled = styled('div')({
  position: 'relative',
  alignSelf: 'flex-start',
  zIndex: 5,
  userSelect: 'none',
});

export const SectionSocialsYoutubeSublineStyled = styled('div')(
  {
    fontSize: '6vw',
    [media('laptop')]: {
      fontSize: '18px',
    },
    [media('desktop')]: {
      fontSize: '21px',
    },
    lineHeight: 1,
    textTransform: 'uppercase',
  },
  (props) => ({
    fontFamily: props.theme.fontSecondary,
    color: props.theme.colorTertiary,
  }),
);

// Instagram

export const SectionSocialsInstagramStyled = styled('div')(
  {
    position: 'absolute',
    width: '100%',
    top: '0',
    left: '0',
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  (props) => ({
    color: props.theme.colorTertiary,
    height: `calc(40% - ${toPx(props.theme.spaceThird)})`,
    border: `1px solid ${props.theme.colorTertiary}`,
    padding: props.theme.spaceHalf,

    [media('laptop')]: {
      height: `calc(50% - ${toPx(props.theme.spaceDouble)})`,
      padding: props.theme.spaceHalf,
    },

    [media('desktop')]: {
      padding: props.theme.spaceEighted,
    },
  }),
);

export const SectionSocialsInstagramIconStyled = styled(Icon)(
  {
    position: 'relative',
    alignSelf: 'flex-end',
    zIndex: 5,
  },
  (props) => ({
    color: props.theme.colorQuaternary,
  }),
);

export const SectionSocialsInstagramContentStyled = styled('div')({
  position: 'relative',
  alignSelf: 'flex-start',
  zIndex: 5,
  userSelect: 'none',
});

export const SectionSocialsInstagramSublineStyled = styled('div')(
  {
    fontSize: '6vw',
    [media('laptop')]: {
      fontSize: '18px',
    },
    [media('desktop')]: {
      fontSize: '21px',
    },
    lineHeight: 1,
    textTransform: 'uppercase',
  },
  (props) => ({
    fontFamily: props.theme.fontSecondary,
    color: props.theme.colorTertiary,
  }),
);

// Facebook

export const SectionSocialsFacebookStyled = styled('div')(
  {
    position: 'absolute',
    width: '100%',
    bottom: '0',
    left: '0',
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  (props) => ({
    height: `calc(60% - ${toPx(props.theme.spaceThird)})`,
    border: `1px solid ${props.theme.colorTertiary}`,
    padding: props.theme.spaceHalf,

    [media('laptop')]: {
      height: `calc(50% + ${toPx(props.theme.space)})`,
      padding: props.theme.spaceHalf,
    },

    [media('desktop')]: {
      padding: props.theme.spaceEighted,
    },
  }),
);

export const SectionSocialsFacebookIconStyled = styled(Icon)(
  {
    position: 'absolute',
    zIndex: 5,
  },
  (props) => ({
    color: props.theme.colorQuaternary,
    right: props.theme.spaceEighted,
    bottom: props.theme.space,
    [media('laptop')]: {
      right: props.theme.spaceHalf,
      bottom: props.theme.spaceHalf,
    },
    [media('desktop')]: {
      right: props.theme.spaceEighted,
      bottom: props.theme.space,
    },
  }),
);

export const SectionSocialsFacebookContentStyled = styled('div')(
  {
    position: 'absolute',
    zIndex: 5,
    userSelect: 'none',
  },
  (props) => ({
    top: props.theme.spaceHalf,
    left: props.theme.spaceHalf,
    right: props.theme.spaceHalf,

    [media('laptop')]: {
      top: props.theme.spaceHalf,
      left: props.theme.spaceHalf,
      right: props.theme.spaceHalf,
    },

    [media('desktop')]: {
      top: props.theme.space,
      left: props.theme.spaceEighted,
      right: props.theme.spaceEighted,
    },
  }),
);

export const SectionSocialsFacebookSublineStyled = styled('div')(
  {
    fontSize: '6vw',
    [media('laptop')]: {
      fontSize: '18px',
    },
    [media('desktop')]: {
      fontSize: '21px',
    },
    lineHeight: 1,
    textTransform: 'uppercase',
  },
  (props) => ({
    fontFamily: props.theme.fontSecondary,
    color: props.theme.colorTertiary,
  }),
);

// Twitter

export const SectionSocialsTwitterStyled = styled('div')(
  {
    position: 'absolute',
    bottom: '0',
    right: '0',
    height: '60%',
  },
  (props) => ({
    width: `calc(50% - ${toPx(props.theme.spaceSixt)})`,
    border: `1px solid ${props.theme.colorTertiary}`,

    [media('laptop')]: {
      height: `calc(50% + ${toPx(props.theme.space)})`,
      width: `calc(50% - ${toPx(props.theme.spaceHalf)})`,
    },

    [media('desktop')]: {
      height: `calc(50% + ${toPx(props.theme.spaceHalf)})`,
      width: `calc(50% - ${toPx(props.theme.space)})`,
    },
  }),
);

export const SectionSocialsTwitterIconStyled = styled(Icon)(
  {
    position: 'absolute',
    zIndex: 5,
  },
  (props) => ({
    color: props.theme.colorQuaternary,
    right: props.theme.spaceEighted,
    bottom: props.theme.space,

    [media('laptop')]: {
      right: props.theme.spaceHalf,
      bottom: props.theme.spaceHalf,
    },
    [media('desktop')]: {
      right: props.theme.spaceEighted,
      bottom: props.theme.space,
    },
  }),
);

export const SectionSocialsTwitterContentStyled = styled('div')(
  {
    position: 'absolute',
    zIndex: 5,
    userSelect: 'none',
  },
  (props) => ({
    top: props.theme.spaceHalf,
    left: props.theme.spaceHalf,
    right: props.theme.spaceHalf,

    [media('laptop')]: {
      top: props.theme.spaceHalf,
      left: props.theme.spaceHalf,
      right: props.theme.spaceHalf,
    },

    [media('desktop')]: {
      top: props.theme.space,
      left: props.theme.spaceEighted,
      right: props.theme.spaceEighted,
    },
  }),
);

export const SectionSocialsTwitterSublineStyled = styled('div')(
  {
    fontSize: '6vw',
    [media('laptop')]: {
      fontSize: '18px',
    },
    [media('desktop')]: {
      fontSize: '21px',
    },
    lineHeight: 1,
    textTransform: 'uppercase',
  },
  (props) => ({
    fontFamily: props.theme.fontSecondary,
    color: props.theme.colorTertiary,
  }),
);
