import React from 'react';
import { graphql } from 'gatsby';
import { parseAsContent } from 'utils/parser';

import WrapPbSection from 'components/common/WrapPbSection';
import WrapSection from 'components/common/WrapSection';

import RichText from 'components/common/RichText';

import { RichTextInnerStyled } from './PageBuilderRichTextStyled';

export default function PageBuilderRichText({ data }) {
  const {
    richtextContent,
    richtextContentMore,
    richtextContentMoreLabel,
  } = data;

  return (
    <WrapPbSection>
      <WrapSection>
        <RichTextInnerStyled>
          <RichText
            moreText={parseAsContent(richtextContentMore?.content)}
            moreTextLabel={richtextContentMoreLabel}
          >
            {parseAsContent(richtextContent?.content)}
          </RichText>
        </RichTextInnerStyled>
      </WrapSection>
    </WrapPbSection>
  );
}

export const query = graphql`
  fragment PageBuilderRichTextQuery on Craft_PageBuilderNeoRichText {
    id
    richtextContent {
      content
    }
    richtextContentMoreLabel
    richtextContentMore {
      content
    }
  }
`;
