import styled from 'utils/emotion';
import { toPx } from 'utils/styles';
import media from 'utils/mediaqueries';

import GridItem from 'components/common/GridItem';
import Embed from 'components/common/Embed';
import TextTeaser from 'components/common/TextTeaser';
import Icon from 'components/common/Icon';

export const ImageTextStyled = styled('div')({
  padding: '0',
  [media('tablet')]: {
    padding: '0 8%',
  },
  [media('laptop')]: {
    padding: '0',
  },
});

export const ImageTextEmbedStyled = styled(Embed)({}, (props) => ({
  marginBottom: props.theme.spaceDouble,

  // No aspect ratio on mobile devices.
  [media(0, 'tablet')]: {
    paddingTop: 0,
  },

  [media('tabletFluid')]: {
    margin: 0,
  },
}));

export const ImageTextTextTeaserStyled = styled(TextTeaser)((props) => ({
  [media('tabletFluid')]: {
    paddingLeft:
      props.teaserStyledAlign === 'right' && toPx(props.theme.spaceDouble),
    paddingRight: props.teaserStyledAlign === 'left' && toPx(0),
  },
  [media('laptop')]: {
    '> div': {
      paddingLeft: props.teaserStyledAlign === 'left' && '0%',
    },
  },
}));

export const ImageTextGridItemStyled = styled(GridItem)(
  {
    order: '-1',
  },
  (props) => ({
    [media('tabletFluid')]: {
      order: props.order,
    },
  }),
);

export const ImageTextEmbedImagePrimaryStyled = styled('div')(
  {
    position: 'absolute!important',
    top: 0,
    height: '100%',
    width: '70%',

    '.gatsby-image-wrapper > div': {
      padding: '71.5%!important',
    },
  },
  (props) => ({
    left: props.align === 'left' && 0,
    right: props.align !== 'left' && 0,
  }),
  // Image alignment overrides any defaults.
  (props) =>
    props.alignImage === 'mainLeftSecondaryRight' && {
      right: 0,
      left: 'auto',
    },
  (props) =>
    props.alignImage === 'mainRightSecondaryLeft' && {
      left: 0,
      right: 'auto',
    },
  (props) =>
    props.hideMobile && {
      [media(0, 'tablet')]: {
        display: 'none',
      },
    },
  (props) =>
    props.hideMobile === false && {
      [media(0, 'tablet')]: {
        position: 'relative !important',
        width: '65%',
        margin: '0 auto',
      },
    },
);

export const ImageTextEmbedImageSecondaryStyled = styled('div')(
  {
    // Full width secondary image on mobile devices.
    position: 'relative !important',
    width: '100%',

    [media('tabletFluid')]: {
      position: 'absolute !important',
      top: '55%',
      height: '40%',
      width: '70%',
      transform: 'translateY(-50%)',
    },

    '.gatsby-image-wrapper > div': {
      padding: '30% !important',
    },
  },
  (props) => ({
    left: props.align !== 'left' && 0,
    right: props.align === 'left' && 0,
  }),
  // Image alignment overrides any defaults.
  (props) =>
    props.alignImage === 'mainLeftSecondaryRight' && {
      left: 0,
      right: 'auto',
    },
  (props) =>
    props.alignImage === 'mainRightSecondaryLeft' && {
      right: 0,
      left: 'auto',
    },
);

export const ImageTextSliderChooserStyled = styled('div')(
  {
    display: 'none',

    [media('tabletFluid')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '70%',
    },
  },
  (props) => ({
    marginLeft: props.align !== 'left' && 'auto',
    height: props.theme.spaceQuad,
    color: props.theme.colorTertiary,
  }),
);

export const ImageTextSliderChooserPrevStyled = styled('div')(
  {
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
  },
  (props) => ({
    paddingLeft: props.theme.space,
    '&:hover': {
      transform: 'translateX(-20%)',
      color: props.theme.colorPrimary,
    },
  }),
);

export const ImageTextSliderChooserNextStyled = styled('div')(
  {
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
  },
  (props) => ({
    paddingRight: props.theme.space,
    '&:hover': {
      transform: 'translateX(20%)',
      color: props.theme.colorPrimary,
    },
  }),
);

export const ImageTextSliderChooserIconStyled = styled(Icon)({
  maxHeight: 'auto',
  maxWidth: 'auto',
  height: '100%',
  width: '36px',
});

export const IndicatorStyled = styled('div')({
  lineHeight: 1,
});
