import React from 'react';
import { graphql } from 'gatsby';

import PageBuilderRichText from 'components/container/PageBuilderRichText';
import PageBuilderStage from 'components/container/PageBuilderStage';
import PageBuilderImageText from 'components/container/PageBuilderImageText';
import PageBuilderSlider from 'components/container/PageBuilderSlider';
import PageBuilderSliderFade from 'components/container/PageBuilderSliderFade';
import PageBuilderContactForm from 'components/container/PageBuilderContactForm';
import PageBuilderVisitForm from 'components/container/PageBuilderVisitForm';
import PageBuilderSocials from 'components/container/PageBuilderSocials';
import PageBuilderAnchor from 'components/container/PageBuilderAnchor';
import PageBuilderVideoGrid from 'components/container/PageBuilderVideoGrid';

import { ContainerStyled } from './PageBuilderStyled';

/**
 * Function:
 * Get component.
 */
const availableComponents = {
  RichText: PageBuilderRichText,
  ImageText: PageBuilderImageText,
  Stage: PageBuilderStage,
  Slider: PageBuilderSlider,
  SliderFade: PageBuilderSliderFade,
  ContactForm: PageBuilderContactForm,
  VisitForm: PageBuilderVisitForm,
  Socials: PageBuilderSocials,
  Anchor: PageBuilderAnchor,
  VideoGrid: PageBuilderVideoGrid,
};

function getComponent(__typename) {
  const componentName = __typename.replace('Craft_PageBuilderNeo', '');
  return availableComponents[componentName] || false;
}

/**
 * Component:
 * Page builder.
 *
 * Renders predefined components dynamically.
 */
export default function PageBuilder({
  components,
  backgroundImage,
  backgroundImages,
}) {
  return (
    <ContainerStyled>
      {components.map((el, index) => {
        const PageBuilderComponent = getComponent(el.__typename);
        const key = `${el.id}-${index}`;

        return PageBuilderComponent ? (
          <PageBuilderComponent
            data={el}
            key={key}
            backgroundImage={backgroundImage}
            backgroundImages={backgroundImages}
          />
        ) : null;
      })}
    </ContainerStyled>
  );
}

/**
 * Fragment:
 * Spreading fragments for page builder components.
 */
export const query = graphql`
  fragment PageBuilderQuery on Craft_PageBuilderNeoUnion {
    __typename
    ...PageBuilderRichTextQuery
    ...PageBuilderStageQuery
    ...PageBuilderImageTextQuery
    ...PageBuilderSliderQuery
    ...PageBuilderSliderFadeQuery
    ...PageBuilderContactFormQuery
    ...PageBuilderVisitFormQuery
    ...PageBuilderSocialsQuery
    ...PageBuilderAnchorQuery
    ...PageBuilderVideoGridQuery
  }
`;
